<script>
import Layout from "@/router/layouts/auth";
import { api } from "@/state/services";
import { mapState, mapActions } from "vuex";
import { required, email, minLength } from "vuelidate/lib/validators";
import {
  checkCpf,
  checkAge,
} from "@/state/validations/register";
import Password from "@/components/widgets/input-password";
import Languages from "@/components/widgets/languages";

export default {
  locales: {
    pt: {
      "Congratulations!": "Parabéns!",
      "Your account has been successfully created.":
        "Sua conta foi criada com sucesso.",
      Name: "Nome",
      "Name is required.": "Nome é obrigatório.",
      Birthday: "Data de Nascimento",
      "Birthday is required.": "Data de nascimento é obrigatória.",
      "E-mail": "E-mail",
      "E-mail is required.": "E-mail é obrigatório.",
      "This value should be a valid e-mail.":
        "Este valor deve ser um e-mail válido.",
      Cellphone: "Celular",
      "Cellphone is required.": "Celular é obrigatório.",
      CPF: "CPF",
      "CPF is required.": "CPF é obrigatório.",
      Password: "Senha",
      Register: "Cadastrar",
      "Click here to login": "Clique aqui para fazer o login",
      "Already have an account?": "Já possui uma conta?",
      Login: "Login",
      "The CPF is incorrect or is already being used.":
        "O cpf está incorreto ou já está sendo utilizado.",
      "Fill in all required fields.": "Preencha todos os campos obrigatórios.",
      "The CPF entered is invalid.": "O CPF digitado é inválido.",
      "Age must be over 18 years.": "A idade deve ser maior que 18 anos.",
    },
    es: {
      "Congratulations!": "¡Felicidades!",
      "Your account has been successfully created.":
        "Tu cuenta ha sido creada satisfactoriamente.",
      Name: "Nombre",
      "Name is required.": "Se requiere el nombre.",
      Birthday: "Fecha de Nacimiento",
      "Birthday is required.": "Fecha de nacimiento é obrigatória.",
      "E-mail": "E-mail",
      "E-mail is required.": "Se requiere correo electrónico.",
      "This value should be a valid e-mail.":
        "Este valor debe ser un correo electrónico válido.",
      Cellphone: "Celular",
      "Cellphone is required.": "Se requiere celular.",
      CPF: "CPF",
      "CPF is required.": "CPF é obrigatório.",
      Password: "Contraseña",
      Register: "Registro",
      "Click here to login": "Haga clic aquí para ingresar",
      "Already have an account?": "¿Ya tienes una cuenta?",
      Login: "Iniciar Sesión",
      "The CPF is incorrect or is already being used.":
        "El cpf es incorrecto o ya se está utilizando.",
      "Fill in all required fields.": "Rellene todos los campos obligatorios.",
      "The CPF entered is invalid.": "El CPF ingresado no es válido.",
      "Age must be over 18 years.": "La edad debe ser mayor de 18 años.",
    },
  },
  components: {
    Layout,
    Languages,
    Password,
  },
  data() {
    return {
      loading: {
        register: false,
        contract: false,
      },
      success: false,
      contract: {
        title: "",
        content: "",
        status: false,
      },
      user: {
        name: "",
        birthday: "",
        cellphone: "",
        cpf: "",
        zipcode: "",
        address: "",
        number: "",
        complement: "",
        district: "",
        city: "",
        state: "",
        email: "",
        password: "",
        term: false,
      },
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  validations: {
    user: {
      name: { required },
      birthday: { required, minLength: minLength(10), checkAge },
      cellphone: { required },
      cpf: { required, checkCpf },

      zipcode: { required },
      address: { required },
      number: { required },
      complement: {},
      district: { required },
      city: { required },
      state: { required },

      email: { required, email },
      password: { required, minLength: minLength(6) },
      term: { required },
    },
  },
  methods: {
    ...mapActions("account", ["register"]),
    getContract() {
      api.get("user/contract").then((res) => {
        if (res.data.status == "success") {
          this.loading.contract = true;
          this.contract.status = res.data.contract.status;
          this.contract.title = res.data.contract.title;
          this.contract.content = res.data.contract.content;
        }
      });
    },
    searchCep() {
      if (this.user.zipcode.length == 9) {
        api.get("https://cep.m2n.com.br/" + this.user.zipcode.replace(/\D/g, "")).then((res) => {
          if (res.data.city == "") {
            this.alert.type = "alert-danger";
            this.alert.message = "O CEP informado é inválido.";
          } else {
            this.alert.type = "";
            this.alert.message = "";

            if (res.data.address) {
              this.user.address = res.data.address;
            }
            if (res.data.district) {
              this.user.district = res.data.district;
            }
            this.user.city = res.data.city;
            this.user.state = res.data.state;
          }
        });
      }
    },
    registerSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.loading.register = true;
        api
          .post("user", {
            name: this.user.name,
            birthday: this.user.birthday,
            cpf: this.user.cpf,
            cellphone: this.user.cellphone,

            zipcode: this.user.zipcode,
            address: this.user.address,
            number: this.user.number,
            complement: this.user.complement,
            district: this.user.district,
            city: this.user.city,
            state: this.user.state,

            email: this.user.email,
            password: this.user.password,
          })
          .then((res) => {
            if (res.data.status == "success") {
              this.user.name = "";
              this.user.birthday = "";
              this.user.cpf = "";
              this.user.cellphone = "";

              this.user.zipcode = "";
              this.user.address = "";
              this.user.number = "";
              this.user.complement = "";
              this.user.district = "";
              this.user.city = "";
              this.user.state = "";

              this.user.email = "";
              this.user.password = "";
              this.$v.$reset();

              this.alert.type = "alert-success";
              this.alert.message = res.data.message;

              this.success = true;
            } else {
              this.alert.type = "alert-danger";
              this.alert.message = res.data.message;
            }

            this.loading.register = false;
          });
      }
    },
  },
  mounted() {
    this.getContract();
  },
};
</script>

<template>
  <Layout>
    <div id="auth" class="row m-0 flex-column-reverse flex-sm-row align-items-center">
      <div class="col">
        <div class="login-content">
          <div class="pt-5 text-center">
            <router-link class="align-middle" tag="a" to="/">
              <img class="w-100" style="max-width: 200px" src="@/assets/images/logo.png" />
            </router-link>
          </div>
          <div class="pt-5">
            <div v-if="success" class="text-center pt-4 pb-4">
              <h5>
                {{ t("Congratulations!") }}<br />{{
                t("Your account has been successfully created.")
                }}
              </h5>
            </div>
            <div v-else>
              <div v-if="alert.message" :class="'alert ' + alert.type">
                {{ t(alert.message) }}
              </div>
              <b-form @submit.prevent="registerSubmit">
                <div class="divisor mb-3">
                  <span>Dados Pessoais</span>
                </div>

                <b-form-group id="name-group" :label="t('Name')" label-for="name">
                  <b-form-input id="name" v-model="user.name" type="text" :class="{ 'is-invalid': $v.user.name.$error }"></b-form-input>
                  <div v-if="!$v.user.name.required" class="invalid-feedback">
                    {{ t("Name is required.") }}
                  </div>
                </b-form-group>
                <b-form-group id="birthday-group" :label="t('Birthday')" label-for="birthday">
                  <b-form-input id="birthday" v-model="user.birthday" v-mask="'##/##/####'" type="text" inputmode="numeric" :class="{ 'is-invalid': $v.user.birthday.$error }"></b-form-input>
                  <div v-if="$v.user.birthday.$error" class="invalid-feedback">
                    <span v-if="!$v.user.birthday.required">{{
                      t("Birthday is required.")
                      }}</span>
                    <span v-if="!$v.user.birthday.minLength">{{
                      t("Birthday is required.")
                      }}</span>
                    <span v-if="!$v.user.birthday.checkAge">{{
                      t("Age must be over 18 years.")
                      }}</span>
                  </div>
                </b-form-group>
                <b-form-group id="cpf-group" :label="t('CPF')" label-for="cpf">
                  <b-form-input id="cpf" v-model="user.cpf" type="text" inputmode="numeric" v-mask="'###.###.###-##'" :class="{ 'is-invalid': $v.user.cpf.$error }"></b-form-input>
                  <div v-if="!$v.user.cpf.required" class="invalid-feedback">
                    {{ t("CPF is required.") }}
                  </div>
                  <div v-if="!$v.user.cpf.checkCpf" class="invalid-feedback">
                    {{ t("The CPF entered is invalid.") }}
                  </div>
                </b-form-group>
                <b-form-group id="cellphone-group" :label="t('Cellphone')" label-for="cellphone">
                  <b-form-input id="cellphone" v-model="user.cellphone" type="text" inputmode="numeric" :class="{ 'is-invalid': $v.user.cellphone.$error }" v-mask="'(##) #####-####'"></b-form-input>
                  <div v-if="!$v.user.cellphone.required" class="invalid-feedback">
                    {{ t("Cellphone is required.") }}
                  </div>
                </b-form-group>

                <div class="divisor mb-3">
                  <span>Endereço</span>
                </div>

                <b-form-group id="user.zipcode" label="CEP" label-for="user.zipcode">
                  <b-form-input v-on:blur="searchCep()" id="user.zipcode" v-model="user.zipcode" type="text" inputmode="numeric" :class="{ 'is-invalid': $v.user.zipcode.$error }" autocomplete="off"
                    v-mask="'#####-###'"></b-form-input>
                  <div v-if="$v.user.zipcode.$error" class="invalid-feedback">
                    <span v-if="!$v.user.zipcode.required">O CEP é obrigatório.</span>
                    <span v-if="!$v.user.zipcode.invalid">O CEP informado é invalido.</span>
                  </div>
                </b-form-group>
                <b-form-group id="user.address" label="Endereço" label-for="user.address">
                  <b-form-input id="user.address" v-model="user.address" type="text" :class="{ 'is-invalid': $v.user.address.$error }" autocomplete="off"></b-form-input>
                  <div v-if="$v.user.address.$error" class="invalid-feedback">
                    <span v-if="!$v.user.address.required">O endereço é obrigatório.</span>
                  </div>
                </b-form-group>
                <b-form-group id="user.number" label="Número" label-for="user.number">
                  <b-form-input id="user.number" v-model="user.number" type="text" :class="{ 'is-invalid': $v.user.number.$error }" autocomplete="off"></b-form-input>
                  <div v-if="$v.user.number.$error" class="invalid-feedback">
                    <span v-if="!$v.user.number.required">O número é obrigatório.</span>
                  </div>
                </b-form-group>
                <b-form-group id="user.complement" label="Complemento" label-for="user.complement">
                  <b-form-input id="user.complement" v-model="user.complement" type="text" :class="{ 'is-invalid': $v.user.complement.$error }" autocomplete="off"></b-form-input>
                  <div v-if="$v.user.complement.$error" class="invalid-feedback">
                    <span v-if="!$v.user.complement.required">O complemento é obrigatório.</span>
                  </div>
                </b-form-group>
                <b-form-group id="user.district" label="Bairro" label-for="user.district">
                  <b-form-input id="user.district" v-model="user.district" type="text" :class="{ 'is-invalid': $v.user.district.$error }" autocomplete="off"></b-form-input>
                  <div v-if="$v.user.district.$error" class="invalid-feedback">
                    <span v-if="!$v.user.district.required">O bairro é obrigatório.</span>
                  </div>
                </b-form-group>
                <b-form-group id="user.city" label="Cidade" label-for="user.city">
                  <b-form-input id="user.city" class="bg-soft-info" v-model="user.city" type="text" :class="{ 'is-invalid': $v.user.city.$error }" autocomplete="off"
                    readonly="readonly"></b-form-input>
                  <div v-if="$v.user.city.$error" class="invalid-feedback">
                    <span v-if="!$v.user.city.required">A cidade é obrigatória.</span>
                  </div>
                </b-form-group>
                <b-form-group id="user.state" label="Estado" label-for="user.state">
                  <b-form-input id="user.state" class="bg-soft-info" v-model="user.state" type="text" :class="{ 'is-invalid': $v.user.state.$error }" autocomplete="off" readonly="readonly"
                    v-mask="'XX'"></b-form-input>
                  <div v-if="$v.user.state.$error" class="invalid-feedback">
                    <span v-if="!$v.user.state.required">O estado é obrigatório.</span>
                  </div>
                </b-form-group>

                <div class="divisor mb-3">
                  <span>Dados de Acesso</span>
                </div>

                <b-form-group id="email-group" :label="t('E-mail')" label-for="email">
                  <b-form-input id="email" v-model="user.email" type="email" :class="{ 'is-invalid': $v.user.email.$error }"></b-form-input>
                  <div v-if="$v.user.email.$error" class="invalid-feedback">
                    <span v-if="!$v.user.email.required">{{
                      t("E-mail is required.")
                      }}</span>
                    <span v-if="!$v.user.email.email">{{
                      t("This value should be a valid e-mail.")
                      }}</span>
                  </div>
                </b-form-group>
                <b-form-group id="password-group" :label="t('Password')" label-for="password">
                  <Password v-model="user.password" :v="$v.user.password"></Password>
                </b-form-group>
                <div v-if="loading.contract && contract.status == 1">
                  <div v-html="contract.content" class="border p-3 overflow-auto" style="height: 200px"></div>
                  <div class="pt-3">
                    <label>
                      <input type="checkbox" v-model="user.term" :class="{ 'is-invalid': $v.user.term.$error }" />
                      {{ contract.title }}
                    </label>
                  </div>
                </div>
                <div class="mt-4">
                  <b-button :disabled="
                      loading.register == true ||
                      (!user.term && contract.status == 1)
                    " type="submit" variant="default" class="btn-lg btn-block">
                    {{ t("Register") }}
                    <b-spinner v-if="loading.register" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
                  </b-button>
                </div>
              </b-form>
            </div>
          </div>
          <div class="mt-4 text-center">
            <p v-if="success" class="text-dark">
              <router-link tag="a" to="/login" class="btn btn-default">{{
                t("Click here to login")
                }}</router-link>
            </p>
            <p v-else class="text-dark">
              {{ t("Already have an account?") }}
              <router-link tag="a" to="/login" class="font-weight-medium text-muted">{{ t("Login") }}</router-link>
            </p>
            <Languages class="d-none" type="dropup"></Languages>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.divisor {
  position: relative;
  text-align: center;
  text-transform: uppercase;
}
.divisor span {
  display: inline-block;
  padding: 0 5px;
  background: #fff;
  font-size: 10px;
  letter-spacing: 2px;
  color: #888;
}
.divisor:before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 52%;
  height: 1px;
  background: #ccc;
}
</style>
